.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 5vmin;
}

.flag {
  max-height: calc(15px + 2vmin);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sound-name {
  font-weight: bold;
}

.App-link {
  color: #ffffff;
  margin: 2vmin 0;
}

.Download-button {
  height: 15vmax;
  max-height: 125px;
}
